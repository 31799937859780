<template>
    <h1>About</h1>
           <Lang/>
</template>
<script>
import Lang from '../components/SwitchLangauge.vue'
export default {
    name: 'About',
    components: {
        Lang
    }
}
</script>