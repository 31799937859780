
<template>


    <div
      v-for="lang in languages"
      :key="lang.lang"
      @click="ChangeLang(lang.lang)"
      color="white"
    >

      <p>
        ppp
        {{ lang.local }}
      </p>
  
  </div>

</template>

<script>
export default {
  name: "LanguageSwitcher",
  data: () => ({
    languages: [
      { lang: "en", local: "Enlish", img: "us.svg" },
      { lang: "kh", local: "Khmer", img: "kh.svg" },
      { lang: "chh", local: "Chinese", img: "chh.png" },
    ],
    flag: "us.svg",
  }),
  methods: {
    ChangeLang(local) {
      if (local != this.$i18n.locale) {
        this.$i18n.locale = local;
        this.$route.params.locale = local
        this.$router.push({
          params: { lang: local },
        });
        if (local == "en") {
          this.flag = "us.svg";
        } else if (local == "kh") {
          this.flag = "kh.svg";
        } else {
          this.flag = "chh.png";
        }
        localStorage.setItem("local", local);
      }
    },
  },
};
</script>
<style scoped>
.v-menu__content {
  margin-top: 12px !important;
  z-index: 9;
}
</style>
